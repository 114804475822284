import React from "react"

export const TrackingContext = React.createContext()

async function getUUID() {
  const url = process.env.GATSBY_GET_UUID_API
  const response = await fetch(url)
  const { id } = await response.json()
  return id
}

const TrackingContextProvider = ({ children }) => {
  const [trackingData, setTrackingData] = React.useState(null)
  const [impactScriptLoaded, setImpactScriptLoaded] = React.useState(false)
  const [customerUUID, setCustomerUUID] = React.useState("")
  const [hasSeenPricingCards, setHasSeenPricingCards] = React.useState(false)
  const [existingUser, setExistingUser] = React.useState(false)
  const [existingCandidate, setExistingCandidate] = React.useState(false)

  const updateTrackingData = React.useCallback(data => {
    setTrackingData(data)
  }, [])

  const loadImpactScript = React.useCallback(() => {
    setImpactScriptLoaded(true)
  }, [])

  const handlePricingCards = React.useCallback(() => {
    setHasSeenPricingCards(true)
  }, [])

  const handleExistingAccount = React.useCallback(type => {
    if (type === "candidate") setExistingCandidate(true)
    if (type === "user") setExistingUser(true)
    if (type === "reset") {
      setExistingUser(false)
      setExistingCandidate(false)
    }
  }, [])

  const value = React.useMemo(
    () => ({
      trackingData,
      updateTrackingData,
      impactScriptLoaded,
      loadImpactScript,
      customerUUID,
      hasSeenPricingCards,
      handlePricingCards,
      existingUser,
      existingCandidate,
      handleExistingAccount,
    }),
    [
      trackingData,
      updateTrackingData,
      impactScriptLoaded,
      loadImpactScript,
      customerUUID,
      hasSeenPricingCards,
      handlePricingCards,
      existingUser,
      existingCandidate,
      handleExistingAccount,
    ],
  )

  React.useEffect(() => {
    async function fetchUUID() {
      if (!customerUUID) {
        const storedUUID = localStorage.getItem("customerUUID")

        if (storedUUID) {
          setCustomerUUID(storedUUID)
        } else {
          const uuid = await getUUID()
          localStorage.setItem("customerUUID", uuid)
          setCustomerUUID(uuid)
        }
      }
    }

    fetchUUID()

    if (!hasSeenPricingCards) {
      const storedPricingCards = sessionStorage.getItem("hasSeenPricingCards")

      if (storedPricingCards) {
        setHasSeenPricingCards(true)
      }
    } else {
      sessionStorage.setItem("hasSeenPricingCards", true)
    }
  }, [customerUUID, hasSeenPricingCards])

  React.useEffect(() => {
    const handleConsentInteraction = event => {
      setTimeout(() => {
        window.location.reload()
      }, 700)
    }

    window.addEventListener("OTConsentApplied", handleConsentInteraction, {
      once: true,
    })

    return () => {
      window.removeEventListener("OTConsentApplied", handleConsentInteraction)
    }
  }, [])

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  )
}

export default TrackingContextProvider

export { TrackingContextProvider }

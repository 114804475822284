const { get } = require("js-cookie")
const qs = require("querystringify")

export function getConsentStatus() {
  const consentCookie = get("OptanonConsent")
  let consentSettings

  if (consentCookie) {
    consentSettings = qs.parse(consentCookie)
  }

  if (
    !consentSettings ||
    (consentSettings.groups && consentSettings.groups.indexOf("C0004:1") !== -1)
  ) {
    return true
  }

  return false
}

// export function getPerformanceConsentStatus() {
//   const performanceCookie = get("OptanonConsent")
//   let performanceSettings

//   if (performanceCookie) {
//     performanceSettings = qs.parse(performanceCookie)
//   }

//   if (
//     !performanceSettings ||
//     (performanceSettings.groups &&
//       performanceSettings.groups.indexOf("C0002:1") !== -1)
//   ) {
//     return true
//   }

//   return false
// }
